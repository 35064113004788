// CAR_BACK(199, "回归待命"),
//   COMPLETED(200, "已完成"),
//   WAIT_AGV(101, "等待agv到达"),
//   AGV_ALLOTED(102, "agv已分配"),
//   WAIT_SENT(103, "agv已到达，等待发货"),
//   ERROR(110, "发生故障"),
//   TERMINATE(105, "暂停物流"),
//   TERMINATE_CONFIRMED(106, "结束物流"),
//   WAIT_RECYCLE(107, "等待回收"),
//   ENABLE_RECYCLE(109, "允许回收");

const stateType = [
  {code: 'TRANSPORTING', codeName: '运输中', type: 1},
  {code: 'CAR_BACK', codeName: '回归待命', type: 1},
  {code: 'COMPLETED', codeName: '已完成', type: 1},
  {code: 'WAIT_AGV', codeName: '等待agv到达', type: 1},
  {code: 'AGV_ALLOTED', codeName: 'agv已分配', type: 1},
  {code: 'WAIT_SENT', codeName: 'agv已到达，等待发货', type: 1},
  {code: 'ERROR', codeName: '发生故障', type: 1},
  {code: 'TERMINATE', codeName: '暂停物流', type: 1},
  {code: 'TERMINATE_CONFIRMED', codeName: '结束物流', type: 1},
  {code: 'WAIT_RECYCLE', codeName: '等待回收', type: 1},
  {code: 'ENABLE_RECYCLE', codeName: '允许回收', type: 1},
  {code: 'WILL_ARRIVE', codeName: '即将到达', type: 2},
  {code: 'COMPLETED', codeName: '收货完成', type: 2},
  {code: 'WAIT_AGV', codeName: '等待小车到达', type: 2},
  {code: 'AGV_ARRIVED', codeName: '小车已到达', type: 2},
]
export function getstate(val) {
  let name='';
  stateType.filter(value => value.type === 1).map(value => {
    if (val == value.code){
      name= value.codeName
    }
  })
  return name
}

export function getnowstate(val) {
  return stateType.filter(value => value.type === 2).map(value => {
    if (val == value.code){
      return value.codeName
    }
  })
}