import { Button, Space } from 'antd';
// import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';    // 路由插件
import axios from 'axios';
const url='http://47.97.72.174:5556'
const App = () => {
    const fetchData = () => {
        setInterval(function() {
            axios.post('https://23-6-8-1-odblymmxmzcwyjl.ros.rmbot.cn/cmd/speed', {
                "vx":0,"vth":0.5
            })
                .then(function (response) {

                })
        },10);
    };
    const fetchData2 = () => {
        axios.post('https://23-6-8-1-odblymmxmzcwyjl.ros.rmbot.cn/cmd/nav', {
            x: 0.76, y: 0.21,
        })
            .then(function (response) {

            })
    };
    return (
        <>
            <Space wrap>
                <Button type="primary"><Link to={'/agv'}  key={'agv'}>agv列表</Link></Button>
                <Button type="primary"><Link to={'/ele'}  key={'ele'}>电梯列表</Link></Button>
                <Button type="primary"><Link to={'/trans'}  key={'trans'}>物流列表</Link></Button>
                {/*<Button type="primary"><Link to={'/map'}  key={'ele'}>地图切换测试</Link></Button>*/}
            </Space>
        </>
    );
};
export default App;