import {FloatButton, Table} from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {RollbackOutlined} from "@ant-design/icons";    // 路由插件
const url='http://47.97.72.174:5556'
const columns = [
    {
        title: '编号',
        dataIndex: 'transNo',
        // render: (_, record) => record.numeration,
        // width: '20%',
    },
    {
        title: 'agv编号',
        dataIndex: 'agvNum',
    },
    {
        title: '类型',
        dataIndex: 'materialType',
        render: (text, record) => {
            return record.status==='ING'?'进行中':'已完成'
        },
    },
    {
        title: '操作',
        dataIndex: '',
        render: (_, record) => (<Link to={'/transCtl'}  state={{...record}}  key={'control'}> 控制</Link>),
        // width: '20%',
    },
];
const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
});
const App = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const fetchData = () => {
        setLoading(true);
        // fetch(`https://randomuser.me/api?${qs.stringify(getRandomuserParams(tableParams))}`)
        //     .then((res) => res.json())
        //     .then(({ results }) => {
        //         setData(results);
        //         setLoading(false);
        //         setTableParams({
        //             ...tableParams,
        //             pagination: {
        //                 ...tableParams.pagination,
        //                 total: 200,
        //                 // 200 is mock data, you should read it from server
        //                 // total: data.totalCount,
        //             },
        //         });
        //     });
        axios.get(url+'/Smart-pin-service/record/trans/', {
            params: {
                current: tableParams.pagination.current,
                size:tableParams.pagination.pageSize
            }
        })
            .then(function (response) {
                // console.log(response);
                let data = response.data.obj
                setData(data.records);
                setLoading(false);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        current: data.current,
                        pageSize:data.size,
                        total: data.total,
                    },
                });
            })
    };

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(tableParams)]);
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };
    return (
        <>
            <Table
                columns={columns}
                rowKey={(record) => record.numb}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <FloatButton icon={<RollbackOutlined />} style={{marginTop:10}} onClick={() => window.history.back()} />
        </>
    );
};
export default App;