import {  Button, Space,message  } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';    // 路由插件
const url='http://192.168.1.22:9098'
let liucheng;
const App = () => {
    const [data, setData] = useState(
        {

        }
    );

    const callsd = (x,y,theta) => {
        axios.get(url+'/call', {
            params: {
                id:500,x,y,theta
            }
        })
            .then(function (response) {
                message.success(response.data,2)
            })
    };
    const changesd = (mapName,x,y,theta) => {
        axios.get(url+'/change', {
            params: {
                id:500,
                mapName,
                theta,
                x,
                y
            }
        })
            .then(function (response) {
                message.success(response.data,2)
            })
    };
    const charge = (x,y,theta) => {
        axios.get(url+'/charge', {
            params: {
                id:500
            }
        })
            .then(function (response) {
                console.log(response.data)
            })
    };
    const call = (x,y,theta) => {
        axios.get(url+'/charge', {
            params: {
                id:500
            }
        })
            .then(function (response) {
                console.log(response.data)
                if (response.data == '发送成功'){
                    clearInterval(liucheng);
                    liucheng=setInterval(function() {
                        call2(10.82,0.05,180)
                    }, 90000);
                }
            })
    };
    const call2 = (x,y,theta) => {
        axios.get(url+'/call', {
            params: {
                id:500,x,y,theta
            }
        })
            .then(function (response) {
                if (response.data == '发送成功'){
                    clearInterval(liucheng);
                    liucheng=setInterval(function() {
                        change('mlnx2',8.21, -2.21, 155.84)
                    }, 40000);
                }
            })
    };
    const change = (mapName,x,y,theta) => {
        axios.get(url+'/change', {
            params: {
                id:500,
                mapName,
                theta,
                x,
                y
            }
        })
            .then(function (response) {
                if (response.data == '发送成功'){
                    clearInterval(liucheng);
                    liucheng=setInterval(function() {
                        call3( 20.50, -8.89, 162.98)
                    }, 40000);
                }
            })
    };
    const call3 = (x,y,theta) => {
        axios.get(url+'/call', {
            params: {
                id:500,x,y,theta
            }
        })
            .then(function (response) {
                if (response.data == '发送成功'){
                    clearInterval(liucheng);
                    liucheng=setInterval(function() {
                        call4(8.21, -2.21, 155.84)
                    }, 50000);
                }
            })
    };
    const call4 = (x,y,theta) => {
        axios.get(url+'/call', {
            params: {
                id:500,x,y,theta
            }
        })
            .then(function (response) {
                if (response.data == '发送成功'){
                    clearInterval(liucheng);
                    liucheng=setInterval(function() {
                        change2('mlnx1',10.82,0.05,180)
                    }, 40000);
                }
            })
    };
    const change2 = (mapName,x,y,theta) => {
        axios.get(url+'/change', {
            params: {
                id:500,
                mapName,
                theta,
                x,
                y
            }
        })
            .then(function (response) {
                if (response.data == '发送成功'){
                    clearInterval(liucheng);
                    liucheng=setInterval(function() {
                        call( -0.17, 0.02, -1.68)
                    }, 40000);
                }
            })
    };
    return (
        <>
            <Space wrap>
                <Button type="primary" onClick={e=>call( )}>开始</Button>
                <Button type="primary" onClick={e=>charge()}>充电</Button>
                <Button type="primary" onClick={e=>callsd(10.82,0.05,180)}>mlnx1电梯点</Button>
                <Button type="primary" onClick={e=>changesd('mlnx2',8.21, -2.21, 155.84)}>坐电梯（切换地图）</Button>
                <Button type="primary" onClick={e=>callsd( 20.50, -8.89, 162.98)}>mlnx2待命点</Button>
                <Button type="primary" onClick={e=>callsd(8.21, -2.21, 155.84)}>mlnx2电梯点</Button>
                <Button type="primary" onClick={e=>changesd('mlnx1',10.82,0.05,180)}>坐电梯（切换地图）</Button>
                {/*<Button type="primary" onClick={e=>call( -0.17, 0.02, -1.68)}>mlnx1待命点</Button>*/}
            </Space>
        </>
    );
};
export default App;