import {Button, Descriptions, Input, message, Select, Space} from 'antd';
import {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import axios from 'axios';

const {Search} = Input;

const url='http://47.97.72.174:5556/smart-ele'
const url2='http://47.97.72.174:5556'
// const url = 'http://192.168.1.66:5557/smart-ele'
const App = () => {
    const {state} = useLocation();
    const [data, setData] = useState({
        ...state
    });
    const [floor, setFloor] = useState({floor: state.floor});
    const [eleState, setEleState] = useState({state: state.state});

    const [eleDOORState, seteleDOORState] = useState({state: ''});
    const askFloor = (text) => {
        axios.get(url + '/ele/control/ask/floor', {
            params: {
                eleId: data.deviceId,
            }
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                // console.log(data)
                message.success(datas.msg)
                if (datas.sucess) {
                    setFloor({floor: datas.obj})
                }
            })
    }
    const handleChange = (value) => {
        setEleState({state: value})
    };
    const handleDOORChange = (value) => {
        seteleDOORState({state: value})
    };
    const seteleState = (text) => {
        axios.get(url + '/ele/change/ele/state', {
            params: {
                eleId: data.deviceId,
                state: eleState.state
            }
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                // console.log(data)
                message.success(datas.msg)
            })
    }

    const goFollr = (value) => {
        axios.get(url + '/ele/control/call/in', {
            params: {
                eleId: data.deviceId,
                floor: value,
            }
        })
            .then(function (response) {
                let datas = response.data
                message.success(datas.msg)
            })
    }
    const setFollr = (value) => {
        axios.putForm(url + '/sim_ele/floor', {
            eleId: data.deviceId,
            floor: value,
        })
            .then(function (response) {
                let datas = response.data
                message.success(datas.msg)
            })
    }
    const setFollrstate = () => {
        axios.putForm(url + '/sim_ele/door', {
            eleId: data.deviceId,
            doorState : eleDOORState.state
        })
            .then(function (response) {
                let datas = response.data
                message.success(datas.msg)
            })
    }
    const outContorl = (value, text) => {

        axios.get(url + '/ele/control/call/out', {
            params: {
                eleId: data.deviceId,
                floor: value,
                up: text
            }
        })
            .then(function (response) {
                let datas = response.data
                message.success(datas.msg)
            })
    }
    const doorControlCmd = (text) => {
        axios.get(url + '/ele/control/door', {
            params: {
                eleId: data.deviceId,
                doorControlCmd: text
            }
        })
            .then(function (response) {
                let datas = response.data
                message.success(datas.msg)
            })
    }

    return (
        <div style={{padding: 10}}>
            <Descriptions title={'操作台'} bordered size={'small'}>
                <Descriptions.Item label="id">
                    {state.deviceId}
                </Descriptions.Item>
                <Descriptions.Item label="当前楼层">
                    {floor.floor}<Button style={{marginLeft: 15}} type="primary"
                                         onClick={e => askFloor()}>询问楼层</Button>
                </Descriptions.Item>
                <Descriptions.Item label="设置状态">
                    <Space wrap>
                        <Select
                            defaultValue={eleState.state}
                            style={{
                                width: 120,
                            }}
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'IN_USE',
                                    label: '使用中',
                                },
                                {
                                    value: 'TROUBLE',
                                    label: '故障',
                                },
                                {
                                    value: 'NO_USE',
                                    label: '未使用',
                                },
                                {
                                    value: 'JINGYONG',
                                    label: '禁用',
                                },
                                {
                                    value: 'WEIXIU',
                                    label: '维修',
                                },
                            ]}
                        />
                        <Button style={{marginLeft: 15}} type="primary" onClick={e => seteleState()}>确定</Button>
                    </Space>
                </Descriptions.Item>

                <Descriptions.Item label="门">
                    <Space wrap>
                        <Button type="primary" onClick={e => doorControlCmd('FOPEN')}>开</Button>
                        <Button type="primary" onClick={e => doorControlCmd('FCLOSE')}>关</Button>
                    </Space>
                </Descriptions.Item>
                {/*<Descriptions.Item label="后门">*/}
                {/*    <Space wrap>*/}
                {/*        <Button type="primary" onClick={e=>doorControlCmd('BOPEN')}>开</Button>*/}
                {/*        <Button type="primary" onClick={e=>doorControlCmd('BCLOSE')}>关</Button>*/}
                {/*    </Space>*/}
                {/*</Descriptions.Item>*/}
                <Descriptions.Item label="外部呼叫">
                    <Search
                        placeholder=""
                        // allowClear
                        enterButton="上行"
                        onSearch={value => outContorl(value, true)}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="外部呼叫">
                    <Search
                        placeholder=""
                        // allowClear
                        enterButton="下行"
                        onSearch={value => outContorl(value, false)}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="去某楼层">
                    <Search
                        placeholder=""
                        // allowClear
                        enterButton="出发"
                        onSearch={goFollr}
                    />
                </Descriptions.Item>

                <Descriptions.Item label="设置楼层">
                    <Search
                        placeholder=""
                        // allowClear
                        enterButton="完成"
                        onSearch={setFollr}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="电梯门状态">
                    <Space wrap>

                        <Select
                            defaultValue={eleDOORState.state}
                            style={{
                                width: 120,
                            }}
                            onChange={handleDOORChange}
                            options={[
                                {
                                    value: 'OPEN',
                                    label: '开',
                                },
                                {
                                    value: 'CLOSE',
                                    label: '关',
                                }
                            ]}
                        />
                        <Button style={{marginLeft: 15}} type="primary" onClick={e => setFollrstate()}>确定</Button>
                    </Space>
                </Descriptions.Item>
            </Descriptions>
            <Button style={{marginTop: 10}}><Link to={'/ele'} key={'index'}> 返回</Link></Button>

        </div>
    );
};
export default App;
