import { Space,Button,Input,Descriptions,message,Select,Modal,FloatButton } from 'antd';
import {
    RollbackOutlined
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import {Link, useLocation} from "react-router-dom";
import axios from 'axios';
const { Search } = Input;
const { Option } = Select;
const url='http://47.97.72.174:5556'
const url2='http://47.97.72.174:6099'
const App = () => {
    useEffect(() => {
        agvNow()
    }, []);
    const { state } = useLocation();
    const [data, setData] = useState({
        ...state
    });

    const [PosInfo, setPosInfo] = useState({});
    const [newAgvNum, setnewAgvNum] = useState('');
    const [MaterialCarPos, setMaterialCarPos] = useState('');
    const [trayControlType, settrayControlType] = useState('CONTROL_0');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const resetContorl= (text) => {
        axios.get(url+'/Smart-Agv-Manager/agvHand/reset', {
            params: {
                deviceId:data.numb,
            }
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                // console.log(data)
                message.success(datas.msg)
            })
    }
    const freeAgv= (text) => {
        axios.get(url+'/Smart-Agv-Manager/agvHand/freeAgv', {
            params: {
                deviceId:data.numb,
            }
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                // console.log(data)
                message.success(datas.msg)
            })
    }
    const continueRun= (text) => {
        if (!data.logisticsId){
            message.warning('暂无物流！')
            return false
        }
        axios.postForm(url+'/Smart-Agv-Manager/agv/continue_run', {
            transNumeration:data.logisticsId,
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                // console.log(data)
                message.success(datas.msg)
            })
    }

    const mapByPoint= (text,fun) => {
        console.log(text)
        let floor= text.split(',')[0];
        let x= text.split(',')[1];
        let y= text.split(',')[2];
        axios.get(url2+'/map/by/point', {
            params: {
                pointx :x,
                pointy :y,
                floor :floor,
            }
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                if (datas.sucess){
                    if (fun == 'navigation'){
                        navigation(datas.obj.id)
                    }else if (fun == 'setAgvPos'){
                        setAgvPos(datas.obj.id)
                    }
                    else if (fun == 'MaterialCar'){
                        setMaterialCarPos(datas.obj.id);
                    }
                    else {
                        message.info('id：'+datas.obj.id)
                    }
                }else {
                    message.error(datas.msg)
                }
            })
            .catch(function (error) {
                // handle error
                // console.log(error);
                message.error(error)
            })
    }

    const ContinueChange=(value)=> {
        // console.log(`selected ${value}`);
        settrayControlType(value)
    }
    const agvNowPos=(e)=> {
        console.log(e.target.value);
        // settrayControlType(value)
        setnewAgvNum(e.target.value);
    }
    const MaterialCarNowPos=(e)=> {
        // settrayControlType(value)
        mapByPoint(e.target.value,'MaterialCar')
    }

    const transportresend= (text) => {
        if (!data.logisticsId){
            message.warning('暂无物流！')
            return false
        }
        axios.postForm(url+'/smart-tranport/transport/app/record/resend', {
            currMaterialCarPosition :MaterialCarPos,
            transNo :data.logisticsId,
            agvNum:newAgvNum
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                if (datas.sucess){
                    message.success('成功！')
                }else {
                    message.error(datas.msg)
                }
            })
            .catch(function (error) {
                // handle error
                // console.log(error);
                message.error(error)
            })
    }
    const transportContinue= (text) => {
        if (!data.logisticsId){
            message.warning('暂无物流！')
            return false
        }
        axios.postForm(url+'/smart-tranport/transport/om/continue', {
            tempPosition :text?text:'',
            transNo :data.logisticsId,
            trayControlType:trayControlType
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                if (datas.sucess){
                    message.success('成功！')
                }else {
                    message.error(datas.msg)
                }
            })
            .catch(function (error) {
                // handle error
                // console.log(error);
                message.error(error)
            })
    }

    const agvNow = (text) => {
        axios.get(url2 + '/agv/'+data.numb, {
            params: {
                id: data.numb,
            }
        })
            .then(function (response) {
                let datas = response.data
                if (datas.sucess) {
                    setData({...datas.obj})
                }
                axios.get(url2 + '/map/by/id', {
                    params: {
                        id: datas.obj.currentPos,
                    }
                })
                    .then(function (response) {
                        let datas2 = response.data
                        message.success(datas.msg)
                        if (datas.sucess) {
                            setPosInfo({...datas2.obj})
                        }
                    })
            })
    }

    const setAgvPos= (value) => {
        axios.get(url+'/Smart-Agv-Manager/agvHand/setAgvPos', {
            params: {
                deviceId:data.numb,
                pos:value
            }
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                // console.log(data)
                message.success(datas.msg);
                if (datas.sucess){
                    setData({...data,currentPos:value})
                }
            })
    }

    const liftingContorl= (text) => {
        axios.get(url+'/Smart-Agv-Manager/agvHand/controllAgvUpDown', {
            params: {
                deviceId:data.numb,
                taskCommand:text,
                goodsids:'100001'
            }
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                // console.log(data)
                message.success(datas.msg)
            })
    }
    const wayContorl= (text) => {
        axios.get(url+'/Smart-Agv-Manager/agvHand/changeMode', {
            params: {
                auto:text,
                deviceId:data.numb,
            }
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                // console.log(data)
                message.success(datas.msg)
            })
    }

    const navigation =(value)=>{
        axios.get(url+'/Smart-Agv-Manager/agvHand/handControllAgvRun', {
            params: {
                deviceId:data.numb,
                end:value,
                start:data.currentPos,
            }
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                // console.log(data)
                // if (datas.obj != null){
                // }else {
                //     message.error('无法导航')
                // }
                message.success(datas.msg,datas.obj)
                if (datas.sucess){
                    setData({...data,currentPos:value})
                }
            })
    }
    let title=<div>操作台<RollbackOutlined  style={{float:"right"}} onClick={() => window.history.back()}/></div>
    return (
        <div style={{padding:10}}>
            <Descriptions title={title} bordered size={'small'}>
                <Descriptions.Item label="agv编号">
                    {data.numb}
                </Descriptions.Item>
                <Descriptions.Item label="物流编号">
                    {data.logisticsId}
                </Descriptions.Item>
                <Descriptions.Item label="当前状态">
                    {data.agvState}
                </Descriptions.Item>
                <Descriptions.Item label="当前位置">
                    {data.currentPos}
                </Descriptions.Item>
                <Descriptions.Item label="当前模式">
                    {data.handControll?'手动':'自动'}
                </Descriptions.Item>
                <Descriptions.Item label="待命点ID">
                    {data.waitPoint}
                </Descriptions.Item>
                <Descriptions.Item label="磁片点编号">
                    {PosInfo.mapId}
                </Descriptions.Item>
                <Descriptions.Item label="磁片点位置">
                    {PosInfo.areaNames}
                </Descriptions.Item>
                <Descriptions.Item label="当前状态">
                    <Space wrap>
                        <Button type="primary" onClick={e=>agvNow()}>获取</Button>
                    </Space>
                </Descriptions.Item>

                {/*<Descriptions.Item label="是否在电梯">*/}
                {/*    {state.inEle}*/}
                {/*</Descriptions.Item>*/}
                <Descriptions.Item label="底盘">
                    <Space wrap>
                        <Button type="primary" onClick={e=>liftingContorl('UP')}>升</Button>
                        <Button type="primary" onClick={e=>liftingContorl('DOWN')}>降</Button>
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="控制方式">
                    <Space wrap>
                        <Button type="primary" onClick={e=>wayContorl(true)}>自动</Button>
                        <Button type="primary" onClick={e=>wayContorl(false)}>手动</Button>
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="设置">
                    <Space wrap>
                        <Button type="primary" onClick={e=>resetContorl()}>复位agv</Button>
                        <Button type="primary" onClick={e=>freeAgv()}>设为空闲</Button>
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="导航id">
                    <Search
                        placeholder=""
                        // allowClear
                        enterButton="出发"
                        onSearch={navigation}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="设置当前位置id">
                    <Search
                        placeholder=""
                        // allowClear
                        enterButton="确定"
                        onSearch={setAgvPos}
                    />
                </Descriptions.Item>

                <Descriptions.Item label="通过坐标获取磁片点id">
                    <Search
                        placeholder="楼层,x,y"
                        // allowClear
                        enterButton="确定"
                        onSearch={mapByPoint}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="导航XY">
                    <Search
                        placeholder=""
                        // allowClear
                        enterButton="出发"
                        onSearch={e=>mapByPoint(e,'navigation')}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="设置当前位置XY">
                    <Search
                        placeholder=""
                        // allowClear
                        enterButton="确定"
                        onSearch={e=>mapByPoint(e,'setAgvPos')}
                    />
                </Descriptions.Item>
                {/*<Descriptions.Item label="设置当前位置">*/}
                {/*    <Search*/}
                {/*        placeholder=""*/}
                {/*        // allowClear*/}
                {/*        enterButton="确定"*/}
                {/*        onSearch={setAgvPos}*/}
                {/*    />*/}
                {/*</Descriptions.Item>*/}
                {/*<Descriptions.Item label="继续物流">*/}
                {/*    <Space wrap>*/}
                {/*        <Button type="primary" onClick={e=>continueRun()}>确定</Button>*/}
                {/*    </Space>*/}
                {/*</Descriptions.Item>*/}
            </Descriptions>
            {/*<FloatButton />*/}
        </div>
    );
};
export default App;
