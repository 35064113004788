import { Space,Button,Input,Descriptions,message,Select,Modal,FloatButton } from 'antd';
import {
    RollbackOutlined
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import {Link, useLocation} from "react-router-dom";
import axios from 'axios';
import {getstate} from './TranSportState'
const { Search } = Input;
const { Option } = Select;
const url='http://47.97.72.174:5556'
const url2='http://47.97.72.174:6099'
const App = () => {
    useEffect(() => {
        TransNow()
    }, []);
    const { state } = useLocation();
    const [data, setData] = useState({
        ...state
    });
    const [newAgvNum, setnewAgvNum] = useState('');
    const [MaterialCarPos, setMaterialCarPos] = useState('');
    const [trayControlType, settrayControlType] = useState('CONTROL_0');

    const mapByPoint= (text,fun) => {
        console.log(text)
        let floor= text.split(',')[0];
        let x= text.split(',')[1];
        let y= text.split(',')[2];
        axios.get(url2+'/map/by/point', {
            params: {
                pointx :x,
                pointy :y,
                floor :floor,
            }
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                if (datas.sucess){
                    setMaterialCarPos(datas.obj.id);
                }else {
                    message.error(datas.msg)
                }

                TransNow()
            })
            .catch(function (error) {
                // handle error
                // console.log(error);
                message.error(error)
            })
    }

    const ContinueChange=(value)=> {
        // console.log(`selected ${value}`);
        settrayControlType(value)
    }
    const agvNowPos=(e)=> {
        console.log(e.target.value);
        // settrayControlType(value)
        setnewAgvNum(e.target.value);
    }
    const MaterialCarNowPos=(e)=> {
        // settrayControlType(value)
        if (e.target.value !== ''){
            mapByPoint(e.target.value,'MaterialCar')
        }
        // setMaterialCarPos(e.target.value);
    }
    const transportresend= (text) => {
        if (data.transportingState != 'TERMINATE_CONFIRMED'){
            message.warning('请先结束物流！')
            return false
        }
        axios.postForm(url+'/smart-tranport/transport/app/record/resend', {
            currMaterialCarPosition :MaterialCarPos,
            transNo :data.transNo,
            agvNum:newAgvNum
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                if (datas.sucess){
                    message.success('成功！')
                }else {
                    message.error(datas.msg)
                }
                TransNow()
            })
            .catch(function (error) {
                message.error(error)
            })
    }
    const transportContinue= (text) => {
        if (!data.transNo){

            message.warning('暂无物流！')
            return false
        }
        axios.postForm(url+'/smart-tranport/transport/om/continue', {
            tempPosition :text?text:'',
            transNo :data.transNo,
            trayControlType:trayControlType
        })
            .then(function (response) {
                // console.log(response);
                let datas = response.data
                if (datas.sucess){
                    message.success('成功！')
                }else {
                    message.error(datas.msg)
                }
                TransNow()
            })
            .catch(function (error) {
                // handle error
                // console.log(error);
                message.error(error)
            })
    }

    const suspendTrans=(val)=>{
        if (val === ''){
            message.warning('请输入中断原因！')
            return false;
        }
        axios.putForm(
            url + '/smart-tranport/transport/web/record/'+data.transNo,{
                reason:val
            },
        )
            .then(function (response) {
                TransNow()
                message.success('成功！')
            })

    }
    const overTrans=(val)=>{
        if (data.transportingState === 'TERMINATE'){
            axios.putForm(url + '/smart-tranport/transport/om/record/terminate/'+data.transNo, {
                transportNumeration:data.transNo,
            })
                .then(function (response) {
                    TransNow()
                    message.success('成功！')
                })
        }else {
            message.warning('请先暂停物流！')
        }
    }
    const TransNow = (text) => {
        axios.get(url + '/smart-tranport/transport/web/record/'+data.transNo, {

        })
            .then(function (response) {
                let datas = response.data
                setData(datas.obj.transportRecord);
            })
    }
    return (
        <div style={{padding:10}}>
            <Descriptions title={'操作台'} bordered size={'small'}>
                <Descriptions.Item label="物流编号">
                    {data.transNo}
                </Descriptions.Item>
                <Descriptions.Item label="agv编号">
                    {data.agvNum}
                </Descriptions.Item>
                <Descriptions.Item label="agv位置">
                    {data.currPosition}
                </Descriptions.Item>
                <Descriptions.Item label="物料车">
                    {data.currMaterialCarNum}
                </Descriptions.Item>
                {/*<Descriptions.Item label="物料车位置">*/}
                {/*    {data.currMaterialCarWaitPosition}*/}
                {/*</Descriptions.Item>*/}
                <Descriptions.Item label="当前状态">
                    <Space wrap>
                        {getstate(data.transportingState)}
                        <Button type="primary" onClick={TransNow}>获取</Button>
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="托盘操作">
                    <Select defaultValue="CONTROL_0" style={{ width: 120 }} onChange={ContinueChange}>
                        <Option value="CONTROL_0">不操作</Option>
                        <Option value="CONTROL_1">先上升再出发</Option>
                        <Option value="CONTROL_2">先到达再上升</Option>
                        <Option value="CONTROL_3">先下降再出发</Option>
                        <Option value="CONTROL_4">先到达再下降</Option>
                    </Select>
                </Descriptions.Item>
                <Descriptions.Item label="手动到达">
                    <Search
                        placeholder="获取当前物料车位置后输入"
                        // allowClear
                        enterButton="确定"
                        onSearch={e=>transportContinue(e)}
                    />
                </Descriptions.Item>
                {
                    getstate(data.transportingState) != "已完成" && getstate(data.transportingState) != "结束物流" && getstate(data.transportingState) != "重新呼车"&&getstate(data.transportingState) == "暂停物流"?
                        <Descriptions.Item label="暂停物流">
                        <Search
                            placeholder="原因"
                            // allowClear
                            enterButton="确定"
                            onSearch={e=>suspendTrans(e)}
                        />
                    </Descriptions.Item>:''
                }
                {
                    getstate(data.transportingState) == "暂停物流"? <Descriptions.Item label="结束物流">
                        <Button type="primary" onClick={overTrans}>结束物流</Button>
                    </Descriptions.Item>:''
                }
                {
                    getstate(data.transportingState) == "结束物流"?<Descriptions.Item label="重新呼车">
                        <Space.Compact>
                            <Input style={{ width: '30%' }} onChange={agvNowPos}  placeholder="新agv" />
                            <Input style={{ width: '40%' }} onBlur={MaterialCarNowPos}  placeholder="物料车点"/>
                            <Button style={{ width: '30%' }} type="primary" onClick={event => transportresend()}>确定</Button>
                        </Space.Compact>
                    </Descriptions.Item>:''
                }
            </Descriptions>
            <FloatButton icon={<RollbackOutlined />} style={{marginTop:10}} onClick={() => window.history.back()} />
        </div>
    );
};
export default App;
